import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";


import brandering from "../images/timber/brandering.png";
import cornices from "../images/timber/Cornices.png";
import rhino from "../images/timber/rhino.png";
import rhinoboard from "../images/timber/rhinoboard.png";
import screwsnails from "../images/timber/screwsnails.png";
import timber from "../images/timber/timber.png";


function TimberPage() {
    return (
      <Layout>
        <SEO
          title="Timber"
          keywords={[`timber`, `rhinoset`, `Zimbabwe`, `brandering`]}
        />
  
       
            <div class="rounded overflow-hidden">
              <img src={brandering}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Brandering</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <img src={timber}  alt="Sunset in the mountains"/>
             
               <div class="font-bold text-xl mb-2">Timber</div>
               <p class="text-gray-700 text-base">
               
               </p>
             
             
            
          
            <div class="rounded overflow-hidden">
              <img src={screwsnails}  alt="Sunset in the mountains"/>
               <div>
               <div class="font-bold text-xl mb-2">Screws and Nails</div>
               <p class="text-gray-700 text-base">
               
               </p>
             </div>
             
             <img src={rhino}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Rhinoset, Rhinobond and Rhino Covebond</div>
               <p class="text-gray-700 text-base">
              
               </p>
             </div>

             


            
           
          
            <div class="rounded overflow-hidden ">
              <img src={rhinoboard}  alt="Sunset in the mountains"/>
               
               <div class="font-bold text-xl mb-2">Rhinoboard</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
               <div class="rounded overflow-hidden ">
              <img src={cornices}  alt="Sunset in the mountains"/>
              
               <div class="font-bold text-xl mb-2">Rhino decorated and plain cornices</div>
               <p class="text-gray-700 text-base">
               
               </p>
               </div>
             
             
        

         
               <h4 className="bg-yellow-400 text-2xl font-bold inline-block my-8 p-3">
          Call or WhatsApp: +263 774 500 868 or +263 773 386 695
        </h4>
         
            
         
        
      </Layout>
    );
  }
  
  export default TimberPage;
  